import React from "react";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import { graphql, Link } from "gatsby";

export const query = graphql`
  query PostQuery($name: String!) {
    file(name: { eq: $name }) {
      childMarkdownRemark {
        frontmatter {
          title
          date(formatString: "MMM D, YYYY")
        }
        html
      }
    }
  }
`;

const Post = ({ data, pageContext }) => {
  const { html } = data.file.childMarkdownRemark;
  const { title, date } = data.file.childMarkdownRemark.frontmatter;
  const { prev, next } = pageContext;

  return (
    <Layout>
      <SEO title={title} />
      <div className="container">
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1">
            <header className="page-header">
              <h1 className="post-title">
                {title} <br />
                <small>{date}</small>
              </h1>
            </header>

            <div dangerouslySetInnerHTML={{ __html: html }} />

            <nav>
              <ul className="pager">
                <li className="previous">
                  {prev !== null && (
                    <Link to={prev}>
                      <span aria-hidden="true">&larr;</span> Previous Post
                    </Link>
                  )}
                </li>
                {next !== null && (
                  <li className="next">
                    <Link to={next}>
                      Next Post <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Post;
